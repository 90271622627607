@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300;400;500&display=swap');

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.landing-img {
  background-image: url('/public/img/todo.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  height: '100vh';
}

body {
  font-family: 'Quicksand', sans-serif;
}
.login-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin: auto;
  height: 100vh;
  width: 960px;
}

.login-container {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 380px;
  height: 200px;
  border-radius: 4px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
}

.login-title {
  font-size: 2.5rem;
  padding-bottom: 20px;
}
.todo li {
  flex: 1;
}

.todo-item {
  padding: 0.5rem 0;
  padding-left: 0.6rem;
}
[contenteditable] {
  outline: none;
}

.todo {
  border-radius: 4px;
  margin: 0.6rem 0 0.5rem;
  background: #faf8f7;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  transition: all 2s ease;
}

.todo-list {
  list-style: none;
}
.trash-btn,
.complete-btn {
  background: #ff6f47;
  color: white;
  border: none;
  padding: 1rem;
  cursor: pointer;
  font-size: 1rem;
}
.complete-btn {
  background: rgb(11, 212, 162);
}
.completed {
  text-decoration: line-through;
  opacity: 0.5;
}

@media screen and (max-width: 600px) {
  .todo-item {
    padding: 0.2rem 0;
    padding-left: 0.6rem;
  }
}
